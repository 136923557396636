@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
    font-family: "Poppins", sans-serif;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
    display: none;
}

.main-wrapper {
    display: flex;
    /*flex: 1;*/
    width: 100vw;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-color: #030a04;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.grantee-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
    /*justify-content: center;*/
    align-items: center;
    /*height: 100vh;*/
    height: 100%;
}

@media only screen and (min-width: 800px) {
    .grantee-wrapper {
        max-width: 475px;
        margin: auto;
    }
}


.grantee-navbar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo {
    height: 30px;
    width: auto;
    margin-top: 10px;
    margin-bottom: 20px;
}

.grantee-header {
    font-size: 34px;
    padding: 10px;
    margin-top: 60px;
    width: 100%;
}

.grantee-name {
    padding: 5px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.grantee-message {
    color: white;
    font-size: 22px;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
}

.bottom-sheet {
    bottom: 0;
    right: 0;
    left: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    padding: 20px;
    -webkit-box-shadow: 0px -3px 13px 2px rgba(0,0,0,0.26);
    box-shadow: 0px -3px 13px 2px rgba(0,0,0,0.26);
    max-width: 100vw;
}

.bottom-sheet .title {
    font-size: 28px;
    text-align: center;
    margin-bottom: 15px
}

.rate-container {
    display: flex;
    flex: 1;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 40px
}

.rate-icon {
    height: 70px;
    width: 70px;
    transition: all 0.2s;
    cursor: pointer;
}

.rate-icon:hover {
    transform: scale(1.1);
}

.center-item {

}

.tip-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: scroll;
    max-width: 100vw;
    margin: 20px -20px;
    scroll-behavior: smooth;

}

.empty-price-area {
    min-width: 100px;
    margin-left: 5px;
    margin-right: 5px;
}

.price-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border: 1px solid white;
    border-radius: 10px;
    min-width: 100px;
    margin-left: 5px;
    margin-right: 5px;
    cursor: pointer;
    transition: all .2s;
}

.price-container.selected {
    background: white;
}

.price {
    font-size: 38px;
    color: white;
    margin: 0;
}

.currency {
    font-size: 12px;
    margin: 0;
}

.price-container.selected .price {
    color: #2AB24C;
}

.price-container.selected .currency {
    color: #2AB24C;
}

.button {
    width: 100%;
    background: #2AB24C;
    padding: 10px;
    cursor: pointer;
    transition: all 0.5s;
    text-align: center;
    border: none;
    border-radius: 15px;
    font-size: 22px !important;
    color: white;
    margin-top: 10px;
}

.button.link {
    background: transparent !important;
    color: #2AB24C;
}

.button span {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.5s;
}

.button span:after {
    content: '\00bb';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -20px;
    transition: 0.5s;
}

.button:hover span {
    padding-right: 25px;
}

.button:hover span:after {
    opacity: 1;
    right: 0;
}

.disabled {
    background: #83868F;
}

.loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
