@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

* {
  font-family: Poppins;
  font-size: 14pt;
  /* background-color: #000000;
  color: #FAFAFA */
}

html {
  scroll-behavior: smooth;
}

/* body {
  overflow: hidden;
} */

.store-button {
  font-size: 12pt;
  padding: 7px 15px;
  border-radius: 5px;
  border: 2px solid #282828;
  transition: .2s all cubic-bezier(0.215, 0.610, 0.355, 1);
  cursor: pointer;
  color: "#ffffff"
}

.store-button.big {
  font-size: 1rem;
}

.nav-link .store-button {
  margin-left: -20px;
}

a.nav-link {
  font-size: 12pt;
  color: #fafafa;
  transition: .2s all cubic-bezier(0.215, 0.610, 0.355, 1);
}

.store-button:hover  {
  color: #2AB24C;
  border: 2px solid #4687f4;
}

a.nav-link:hover {
  color: #2AB24C;
}

.hero-part {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 85vh;
  justify-content: center;
  padding: 40px;
  /* align-items: center; */
}

.hero-subtitle, .hero-subtitle span {
  font-size: 1.5rem;
  margin-bottom: .5rem;
  line-height: 1.2;
}

.primary-highlight {
  color: #2AB24C;
}

.section {
  padding: 40px 10px;
}

.profile_image {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  border: 2px solid white;
  font-size: 20pt;
  font-weight: bold;
  color: white;
}

.thumb-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 35px 0 30px 0;
}

.thumbs {
  height: 120px;
  width: 120px;
  cursor: pointer;
  transition: all .2s ease;
}

.thumbs:hover {
  transform: scale(1.1);
}

/* .thumbs .mute {

} */