.container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: -60px;
}

.corner-wrapper {
    position: relative;
    width: 80vw;
    height: 80vw;
    padding: 10px;
    display: flex;
    justify-content: center;
    /*align-items: center;*/
}

.corner {
    position: absolute;
    border: 5px solid #2AB24C;
    border-radius: 5px;
    height: 8vw;
    width: 8vw;
}

.tl {
    left: 0;
    top: 0;
    border-top-width: 5px;
    border-left-width: 5px;
    border-right-width: 0;
    border-bottom-width: 0;
}

.tr {
    top: 0;
    right: 0;
    border-top-width: 5px;
    border-right-width: 5px;
    border-left-width: 0;
    border-bottom-width: 0;
}

.bl {
    bottom: 0;
    right: 0;
    border-bottom-width: 5px;
    border-right-width: 5px;
    border-left-width: 0;
    border-top-width: 0;
}

.br {
    bottom: 0;
    left: 0;
    border-bottom-width: 5px;
    border-left-width: 5px;
    border-right-width: 0;
    border-top-width: 0;
}

.message-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.message-wrapper .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #030b04bb;
}

.message-wrapper h4 {
    text-align: center;
}



.scan-line {
    position: absolute;
    width: 73vw;
    height: 3px;
    background: white;
    animation: MoveUpDown 2.5s linear infinite;
}

@keyframes MoveUpDown {
    0%, 100% {
        top: calc(0% + 10px);
    }
    50% {
        top: calc(100% - 10px);
    }
}

.qr-container {
    padding: 10px;
    background: white;
    border-radius: 10px;
}

@media only screen and (min-width: 800px) {
    .qr-wrapper {
        max-width: 475px;
        margin: auto;
    }
}


